@import "custom";
@import "modal";
@import "payment";
@import "form";

.page-container {
  min-height: 100vh;
}

.text-right {
  text-align: right;
}

.flex-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bolder {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.media-icon-fb {
  font-size: 3.0rem;
  &:hover {
    color: #3b5998 !important;
    cursor: pointer;
  }
  transition: color 0.2s ease-in-out;
}

.media-icon-ig {
  font-size: 3.3rem;

  &:hover {
    cursor: pointer;
    color: #131516 !important;
  }

  transition: color 0.2s ease-in-out;
  color: white !important;
}

hr.solid-divider {
  border-radius: 100px;
  border-top: 1px solid white;
}

.policy {
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background: white;
}

.policy-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 25px;
}

.pointer {
  cursor: pointer;
}

.required-tip {
  color: #dc3545;
  margin-left: 2px;
}

.spinner-border-custom {
  width: 1.3rem;
  height: 1.3rem;
  margin-left: 7px;
  border-width: 0.2em;
}

.password-reset-card {
  margin-top: 5px;
  display: flex;
  justify-content: center;
}

.reset-title {
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 15px;
}

.reset-password-container {
  background-color: $light;
  padding: 50px 0;
}

.tab-box {
  padding: 0 0 15px 0;
  border-radius: 0.25rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: white;
  border: 1px solid #dee2e6;
}

.user-tab {
  margin-left: -1px;
  margin-top: -1px;
}

.text-space {
  margin-left: 5px;
}

.subscription-display {
  font-size: .9rem;
  font-weight: 600;
  margin: 20px 15px;
  color: $success;
  text-align: center;
}

.subscription-display-ended {
  font-size: .9rem;
  font-weight: 600;
  margin: 20px 15px;
  color: $dark;
  text-align: center;
}

.user-custom-tab {
  min-height: 40vh;
}

.subscription-display-tab {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.sub-content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sub-display-box {
  border: 1px solid #dee2e6;
  padding: 7px 10px;
  border-radius: .25rem;
  background: #f8f9fa !important;
}

.gym-sub {
  text-decoration: underline $purple;
  color: $purple;
  font-weight: 700;
}

.online-sub {
  text-decoration: underline $orange;
  color: $orange;
  font-weight: 700;
}

.gym-sub-end {
  text-decoration: underline #cc0000;
  color: #cc0000;
}

.subscription-display-title {
  text-align: center;
  font-size: 1rem;
}

.subscription-display-date {
  margin: 10px 0 0 0;
  font-size: 1rem;
  font-weight: 700;
}

.subscription-display-days {
  font-size: 1rem;
}

.subscription-display-end {
  font-size: 1.2rem;
  font-weight: 600;
  color: $dark;
}

.btn-extend-sub {
  margin: 13px;
}

.user-info-tab {

}

.info-tab-form {
  display: block;
  margin-top: 10px;
  padding-bottom: 10px;
}

.tab-user-name {
  font-weight: 600;
  border-bottom: 1px solid #dee2e6;
  color: $dark;
}

.tab-email {
  font-weight: 700;
}

.tab-email-change {
  font-weight: 600;
}

.tab-user-email {
  margin-top: 1rem;
}

.user-tab-hr {
  margin-top: 2.1rem;
  margin-bottom: 0.2rem;
}

.product-container {
  padding-top: 15px;
  text-align: center;
}

.product-title {
  font-size: 1rem;
  font-weight: 600;
  color: $dark;
}

.product-price {
  font-size: 2rem;
  font-weight: 600;
  color: $dark;
}

.product-description {
  white-space: pre-wrap;
  padding: 5px 0 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invoice-chosen {
  font-weight: 600;
  color: #212529;
  text-decoration: underline;
}

.workout-about-box {
  white-space: pre-wrap;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}


$theme-colors: (

);

$transition-time: 0.15s;

body {
  font-family: 'Open Sans', sans-serif !important;
}

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

.btn {
  border-radius: 0.35rem;
  padding: 0.4rem 0.9rem;
  line-height: 1.7;
  transition: color $transition-time ease-in-out, background-color $transition-time ease-in-out, border-color $transition-time ease-in-out, box-shadow $transition-time ease-in-out;
}

.form-control {
  line-height: 1.7;
  height: calc(1.5em + 0.75rem + 4px);
}

.loading-background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  transition: opacity 1s ease-in;
}
.loading-background .loading-bar {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
}
.loading-background .loading-bar .loading-circle-1,
.loading-background .loading-bar .loading-circle-2 {
  content: " ";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #e74c3c;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}
.loading-background .loading-bar .loading-circle-2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.loading-bar-hidden {
  transition: opacity .5s ease-out;
  opacity: 0 !important;
}

.loading-hidden {
  transition: opacity .8s ease-out;
  transition-delay: .5s;
  opacity: 0;

  -moz-animation: hideAnimation 0s ease-in .8s forwards;
  /* Firefox */
  -webkit-animation: hideAnimation 0s ease-in .8s forwards;
  /* Safari and Chrome */
  -o-animation: hideAnimation 0s ease-in .8s forwards;
  /* Opera */
  animation: hideAnimation 0s ease-in .8s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes hideAnimation {
  to {
    width:0;
    height:0;
    overflow:hidden;
  }
}
@-webkit-keyframes hideAnimation {
  to {
    width:0;
    height:0;
    visibility:hidden;
  }
}

.spinner-box {
  min-height: 70vh;
  color: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255,255,255,0.75);
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem
}

.no-data-display {
  padding: 24px;
}

// This is pagination pages per page selector, it hides on mobile
.eHxVgR {
  display: none;
}

.img-uploader > div {
  background-color: #f8f9fa !important;
  p {
    display: none;
  }
  .chooseFileButton {
    margin-top: 1.5rem;
    @extend .btn;
    @extend .btn-primary;
  }

  .uploadPictureContainer {
    width: 100% !important;
    max-width: 500px !important;
  }
}

.sub-not-active {
  font-weight: 600;
  color: #cc0000;
}

.sub-active {
  font-weight: 600;
  color: #28a745;
}

.sub-expired {
  font-weight: 600;
  color: #fd7e14;
}

.modal-title {
  text-align: center;
  font-weight: bolder;
  margin-bottom: 1rem;
}

.flex-align-end {
  display: flex;
  align-items: flex-end;
}

.flex-je-ac {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.vh-55 {
  height: 55vh;
}

.days-display {
  color: $dark;
}

.badge-online {
  background-color: $orange;
}

.badge-hall {
  background-color: $purple;
}

.badge-full {
  background-color: #146ec8;
}

.badge-times {
  background-color: darkturquoise;
}

.workouts-online-title {
  font-weight: 500;
  padding-top: 1rem;
  color: #373D3F;
}

.workouts-free-title {
  color: #373D3F;
  border: 1px solid #50b4d6 !important;
  background-color: #fce54abf !important;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
}

.sub-online-item {
  font-weight: 600;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #f8f9fa;
  color: #373D3F;
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.sub-gym-item {
  font-weight: 600;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  color: #373D3F;
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
  background-color: #f8f9fa;
  padding: .25rem 0;
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.workout-date {
  font-weight: 500;
  color: #555F61;
  text-align: center;
  font-size: .75rem;
}

.workout-watch-btn {
  max-width: 160px;
}

.workout-title {
  font-size: 1rem;
  font-weight: 600;
  color: $dark;
  text-align: center;
}

.workout-desc {
  text-align: center;
}

.iframe-substitute {
  width: 100%;
  height: 60px;
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
}

.iframe-box {
  position: relative;
  height: 480px;
}

.responsive-iframe {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.workout-date-created {
  font-weight: 600;
  color: #707C80;
}

.workout-desc-view {
  color: $dark;
}

.new-workout {
  font-size: .85rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  font-weight: 700;
  color: $black;
}

.new {
  color: $red;
}
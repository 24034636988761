.payment-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: $yiq-text-dark
}

.payment-item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.payment-summary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.payment-item-title {
  font-weight: 600;
  text-align: center;
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.payment-item-desc {
  white-space: pre-wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.payment-item-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 0px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 3px;
}

.payment-total {
  font-size: 1.5rem;
  font-weight: 600;
  color: #373D3F;
}

.payment-price {
  font-size: 1.5rem;
  font-weight: 600;
  color: #373D3F;
}

.payment-desc-text {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 17px;
  border-radius: 5px;
  text-align: center;
}

.payment-summary-box {
  min-height: 240px;
}

.payment-type-info {
  display: block;
  font-size: 80%;
  font-weight: 400;
  color: #6c757d !important;
}

.pay-btn {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
}

.paysera {
  max-height: 40px;
  margin-top: .7rem;
}

.payment-next-step {
  display: block;
  font-size: 80%;
  font-weight: 400;
  color: #6c757d !important;
  margin-top: .5rem;
}

.payment-error-block {
  margin: 1rem 0 0 0;
}

.payment-info {
  font-size: 1.5rem;
}

.input-group-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: fit-content;
  border: 1px solid #ced4da;
  border-radius: 10px;

  button,
  input {
    border: none;
    outline: none;
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }

  input {
    width: 80px; /* Set a fixed width for input to fit smaller forms */
    text-align: center;
  }

  button {
    cursor: pointer;
    background-color: #f3f3f3; /* Optional: lighter background */
    color: #373D3F;
  }

  button:nth-last-child(1) {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  button:nth-child(1) {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  }

  button:hover {
    background-color: #e0e0e0;
  }

  button:nth-last-child(1):active {
    box-shadow: 0 0 0 0.2rem rgba(224, 224, 224, 0.5);
  }

  button:nth-child(1):active {
    box-shadow: 0 0 0 0.2rem rgba(224, 224, 224, 0.5);
  }
}
.table-expanded-row {
  background-color: #f8f9fa !important;
  margin-top: .5rem;
  margin-bottom: .5rem;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  padding: .5rem;
}

.search-input {
  max-width: 200px;
  margin-top: 1rem;
  margin-bottom: -.5rem;
  display: inline-block;
}

.landing-title {
  font-size: 3rem;
  font-weight: 600;
  // color: #50b4d6;
  color: #343a40 !important;
}

.landing {
  // height: 80vh;
  background-color: #f8f9fa!important;
}

.landing-action {
  margin-top: 2rem;
  *{
    margin: 0 10px;
  }
}

.landing-intro {
  font-size: 1.5rem;
  font-weight: 600;
}

.box1 {
  background-color: #FCE54A !important;
  border-radius: 0 0 22px 22px;
}

.box2 {
  background-color: #50b4d6 !important;
  border-radius: 22px 22px 0 0;
  padding-bottom: 2rem;
}

.social-description {
  color: white;
}

.about-us-title {
  color: white;
  font-size: 1.8rem;
  font-weight: 600;
  margin-top: 1rem;
}

.read-more {
  color: white;
  text-decoration: underline;
  font-size: 1.1rem;
  margin-top: -0.4rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  max-height: 30px;
}

.max-height-animation {
  -webkit-transition: max-height 1s ease-in;
  -moz-transition: max-height  1s ease-in;
  -ms-transition: max-height  1s ease-in;
  -o-transition: max-height  1s ease-in;
  transition: max-height  1s ease-in;
}

.max-height-animation-read {
  -webkit-transition: max-height 0.2s ease-in;
  -moz-transition: max-height  0.2s ease-in;
  -ms-transition: max-height  0.2s ease-in;
  -o-transition: max-height  0.2s ease-in;
  transition: max-height  0.2s ease-in;
}

.about-us-text {
  color: white;
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-height: 500px;
  overflow: hidden;

  @extend .max-height-animation;
}

.about-us-overflow {
  position: relative;
  max-height: 80px;
}

.about-us-overflow:after {
  content  : "";
  position : absolute;
  z-index  : 1;
  bottom   : 0;
  left     : 0;
  pointer-events   : none;
  background-image : linear-gradient(to bottom,
          rgba(#50b4d6, 0),
          rgba(#50b4d6, 1) 100%);
  width    : 100%;
  height   : 3em;
}

.hidden {
  overflow: hidden;
  max-height: 0px;
  @extend .max-height-animation-read;
}

.timetable {
  font-size: 1rem;
  font-weight: 600;
  color: #555F61;
  text-decoration: underline;
  transition: color 0.15s ease-in;
  padding-top: 1rem;

  &:hover {
    color: #007bff;
  }

  &:active {
    color: #007bff;
  }
}

.t-arrow {
  margin: 0 0.5rem 0 0.5rem;
  transition: 1s;
}

.t-arrow-r {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.t-arrow-f {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.timetable-background {
  background: transparent no-repeat center;
  background-size: cover;
  transition: 1s ease-in;
  margin-top: -24px;
}

.timetable-background-open {
  margin-top: 0;
}

.timetable-photo {
  width: 100%;
  transition: 1s;
}

.timetable-photo-closed {
  width: 0;
}

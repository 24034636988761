.login-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 576px) {
  .modal-md {
    max-width: 380px !important;
    margin: 1.75rem auto;
  }

  .login-modal {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.hyperlink {
  cursor: pointer;
  text-decoration: underline;
  color: $primary;
}

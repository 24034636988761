.navbar {
  border-bottom: 1px solid #e6e6e6;
  background: #fff;
  padding: 0.25rem;
}

.logo {
  width: 50px;
}

.logo-text {
  width: 180px;
  margin-left: 10px;
}

.brand {
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 575px) {
  .nav-btn {
    margin-right: auto !important;
    margin-left: auto !important;
    display: block;
  }
}

.logout {
  margin: 5px;
}
